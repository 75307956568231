import React, { useState, useEffect } from 'react';
import rawDataContent from './raw_data.json';
import { motion } from 'framer-motion';
import { FaClipboardList, FaUser, FaHospital, FaChartBar } from 'react-icons/fa';

const HerniaFormPreview = () => {
  const [activeTab, setActiveTab] = useState('raw-data');
  const [formData, setFormData] = useState({});
  const [rawData, setRawData] = useState({ operative_report: '', discharge_summary: '' });

  useEffect(() => {
    setRawData(rawDataContent);
  }, []);

  const TabButton = ({ id, label, icon }) => (
    <motion.button
      whileHover={{ scale: 1.05, backgroundColor: '#4F46E5' }}
      whileTap={{ scale: 0.95 }}
      className={`flex items-center justify-center py-3 px-4 ${
        activeTab === id
          ? 'bg-indigo-600 text-white'
          : 'bg-white text-indigo-600 hover:bg-indigo-50'
      } font-medium rounded-lg transition-all duration-200 ease-in-out text-sm shadow-md w-full sm:w-auto`}
      onClick={() => setActiveTab(id)}
    >
      {icon}
      <span className="ml-2">{label}</span>
    </motion.button>
  );

  const FieldWithContext = ({ label, type, options, fieldKey, prediction }) => {
    const handleAcceptPrediction = () => {
      setFormData(prev => ({ ...prev, [fieldKey]: prediction.value }));
    };

    return (
      <div className="mb-4 bg-gray-100 bg-opacity-50 p-4 rounded-lg">
        <label className="block mb-1 font-semibold text-gray-700">{label}</label>
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="w-full sm:w-64">
            {type === 'select' ? (
              <select
                value={formData[fieldKey] || ''}
                onChange={(e) => setFormData(prev => ({ ...prev, [fieldKey]: e.target.value }))}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 bg-white"
              >
                <option value="">Select...</option>
                {options && options.map(opt => (
                  <option key={opt} value={opt}>{opt}</option>
                ))}
              </select>
            ) : type === 'checkbox' ? (
              <div className="flex items-center">
                <input 
                  type="checkbox"
                  checked={formData[fieldKey] || false}
                  onChange={(e) => setFormData(prev => ({ ...prev, [fieldKey]: e.target.checked }))}
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
                <span className="ml-2 text-gray-700">Yes</span>
              </div>
            ) : (
              <input 
                type={type}
                value={formData[fieldKey] || ''}
                onChange={(e) => setFormData(prev => ({ ...prev, [fieldKey]: e.target.value }))}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 bg-white"
              />
            )}
          </div>
          <div className="flex-1 bg-indigo-50 p-3 rounded-md shadow-sm">
            <p className="text-indigo-700 font-semibold text-sm mb-1">
              Prediction: {type === 'checkbox' ? (prediction.value ? 'Yes' : 'No') : prediction.value}
            </p>
            <p className="text-indigo-600 text-xs mb-2">Context: {prediction.context}</p>
            <button 
              onClick={handleAcceptPrediction}
              className="bg-indigo-500 hover:bg-indigo-600 text-white text-sm py-1 px-3 rounded-md transition-colors duration-200 ease-in-out"
            >
              Accept
            </button>
          </div>
        </div>
      </div>
    );
  };

  const operativeDetailsFields = [
    { label: "Hernia Width (cm)", type: "number", fieldKey: "hernia_width",
      prediction: { value: "15", context: "The hernia defect measured approximately 15 cm in width and 20 cm in length." } },
    { label: "Hernia Length (cm)", type: "number", fieldKey: "hernia_length",
      prediction: { value: "20", context: "The hernia defect measured approximately 15 cm in width and 20 cm in length." } },
    { label: "Hernia Type", type: "select", fieldKey: "hernia_type", 
      options: ["Diastasis recti", "Epigastric", "Incisional", "Lumbar", "Parastomal", "Spigelian", "Umbilical"],
      prediction: { value: "Incisional", context: "Preoperative Diagnosis: Large incisional hernia" } },
    { label: "Indication for Hernia Repair", type: "select", fieldKey: "indication_hernia_repair", 
      options: ["Bowel obstruction", "Enlarging hernia, interfering with activities", "Fistula", "Infected mesh", "Pain"],
      prediction: { value: "Enlarging hernia, interfering with activities", context: "Preoperative Diagnosis: Large incisional hernia" } },
    { label: "Operative Approach", type: "select", fieldKey: "operative_approach", 
      options: ["Laparoscopic", "Laparoscopy-assisted", "MIS converted to open", "Open", "Robotic", "Robotic-assisted"],
      prediction: { value: "Open", context: "Procedure Performed: Open Transversus Abdominis Release (oTAR) with mesh placement" } },
    { label: "Procedure Performed", type: "select", fieldKey: "procedure_performed", 
      options: ["Open Transversus Abdominis Release (oTAR) with mesh placement", "Laparoscopic hernia repair", "Robotic hernia repair"],
      prediction: { value: "Open Transversus Abdominis Release (oTAR) with mesh placement", context: "Procedure Performed: Open Transversus Abdominis Release (oTAR) with mesh placement" } },
    { label: "Operative time (minutes)", type: "select", fieldKey: "operative_time", 
      options: ["0-59", "60-119", "120-179", "180-239", "240+"],
      prediction: { value: "240+", context: "Operative time: 245 minutes" } },
    { label: "Estimated blood loss (mL)", type: "number", fieldKey: "estimated_blood_loss",
      prediction: { value: "100", context: "Estimated blood loss: 100 mL" } },
    { label: "Wound class", type: "select", fieldKey: "wound_class", 
      options: ["Clean", "Clean-Contaminated", "Contaminated", "Dirty"],
      prediction: { value: "Clean", context: "Wound class: Clean" } },
    { label: "Mesh used", type: "checkbox", fieldKey: "mesh_used",
      prediction: { value: true, context: "A 30 x 30 cm polypropylene mesh was placed in the retromuscular space and fixed using transfascial sutures." } },
    { label: "Drain placement", type: "select", fieldKey: "drain_placement", 
      options: ["None", "Subcutaneous", "Retromuscular", "Intraperitoneal"],
      prediction: { value: "Retromuscular", context: "A 19 Fr Blake drain was placed in the retromuscular space." } },
    { label: "TAP block performed", type: "checkbox", fieldKey: "tap_block_performed",
      prediction: { value: true, context: "TAP block performed: Yes" } },
  ];

  const postoperativeDetailsFields = [
    { label: "Length of Stay (days)", type: "number", fieldKey: "length_of_stay",
      prediction: { value: "5", context: "Length of Stay: 5 days" } },
    { label: "Surgical Site Infection", type: "checkbox", fieldKey: "surgical_site_infection",
      prediction: { value: false, context: "Surgical Site Infection (SSI): No" } },
    { label: "Surgical Site Occurrence", type: "checkbox", fieldKey: "surgical_site_occurrence",
      prediction: { value: true, context: "Surgical site occurrence (SSO): Yes (small seroma, managed conservatively)" } },
    { label: "SSO Requiring Intervention", type: "checkbox", fieldKey: "sso_requiring_intervention",
      prediction: { value: false, context: "SSO requiring intervention: No" } },
    { label: "Readmission", type: "select", fieldKey: "readmission",
      options: ["Yes", "No", "N/A"],
      prediction: { value: "N/A", context: "Readmission: N/A" } },
    { label: "Reoperation", type: "checkbox", fieldKey: "reoperation",
      prediction: { value: false, context: "Reoperation: No" } },
    { label: "Pain Management", type: "select", fieldKey: "pain_management",
      options: ["IV PCA", "Oral medications", "Epidural", "Other"],
      prediction: { value: "Oral medications", context: "Pain was well-controlled with IV patient-controlled analgesia, which was transitioned to oral pain medications on postoperative day 2." } },
  ];

  const demographicsFields = [
    { label: "Date of Birth", type: "date", fieldKey: "dob",
      prediction: { value: "1980-05-15", context: "DOB: 1980-05-15" } },
    { label: "Sex", type: "select", fieldKey: "sex",
      options: ["Male", "Female", "Other"],
      prediction: { value: "Male", context: "Sex: Male" } },
    { label: "Date of Surgery", type: "date", fieldKey: "date_of_surgery",
      prediction: { value: "2023-08-14", context: "Date of Surgery: 2023-08-14" } },
    { label: "Surgeon", type: "text", fieldKey: "surgeon",
      prediction: { value: "Dr. Jane Smith", context: "Surgeon: Dr. Jane Smith" } },
    { label: "Assistant", type: "text", fieldKey: "assistant",
      prediction: { value: "Dr. Robert Johnson", context: "Assistant: Dr. Robert Johnson" } },
    { label: "Anesthesiologist", type: "text", fieldKey: "anesthesiologist",
      prediction: { value: "Dr. Sarah Lee", context: "Anesthesiologist: Dr. Sarah Lee" } },
  ];

  return (
    <div className="container mx-auto p-6 font-sans bg-gradient-to-br from-indigo-50 to-purple-50 min-h-screen">
      <div className="bg-white p-8 rounded-2xl shadow-2xl mb-8 relative overflow-hidden">
        <div className="absolute top-0 left-0 w-full h-2 bg-gradient-to-r from-indigo-500 to-purple-500" />
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-4xl font-extrabold mb-2 bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 to-purple-600">
              Hernia Reporting Form
            </h1>
            <p className="text-xl text-gray-600">
              Complete the form below to submit a hernia report
            </p>
          </div>
          <div className="text-2xl font-bold text-indigo-600">
            bezl.ai
          </div>
        </div>
      </div>

      <div className="flex flex-col sm:flex-row gap-2 sm:space-x-4 mb-6">
        <TabButton id="raw-data" label="Raw Data" icon={<FaClipboardList className="text-lg" />} />
        <TabButton id="demographics" label="Demographics" icon={<FaUser className="text-lg" />} />
        <TabButton id="operative-details" label="Operative Details" icon={<FaHospital className="text-lg" />} />
        <TabButton id="postoperative-details" label="Postoperative Details" icon={<FaChartBar className="text-lg" />} />
      </div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-white p-6 rounded-2xl shadow-xl"
      >
        {activeTab === 'raw-data' && (
          <div>
            <h2 className="text-2xl font-bold mb-4 text-indigo-700">Raw Data</h2>
            <h3 className="text-xl font-semibold mb-2 text-indigo-600">Operative Report</h3>
            <pre className="whitespace-pre-wrap bg-gray-50 p-4 rounded-lg overflow-auto max-h-[300px] mb-4 text-left border border-gray-200 text-sm">
              {rawData.operative_report}
            </pre>
            <h3 className="text-xl font-semibold mb-2 text-indigo-600">Discharge Summary</h3>
            <pre className="whitespace-pre-wrap bg-gray-50 p-4 rounded-lg overflow-auto max-h-[300px] text-left border border-gray-200 text-sm">
              {rawData.discharge_summary}
            </pre>
          </div>
        )}
        {activeTab === 'demographics' && (
          <div>
            <h2 className="text-2xl font-bold mb-4 text-indigo-700">Demographics</h2>
            {demographicsFields.map((field, index) => (
              <FieldWithContext key={index} {...field} />
            ))}
          </div>
        )}
        {activeTab === 'operative-details' && (
          <div>
            <h2 className="text-2xl font-bold mb-4 text-indigo-700">Operative Details</h2>
            {operativeDetailsFields.map((field, index) => (
              <FieldWithContext key={index} {...field} />
            ))}
          </div>
        )}
        {activeTab === 'postoperative-details' && (
          <div>
            <h2 className="text-2xl font-bold mb-4 text-indigo-700">Postoperative Details</h2>
            {postoperativeDetailsFields.map((field, index) => (
              <FieldWithContext key={index} {...field} />
            ))}
          </div>
        )}
      </motion.div>

      <div className="mt-8 flex justify-center">
        <motion.button
          whileHover={{ scale: 1.05, backgroundColor: '#4338CA' }}
          whileTap={{ scale: 0.95 }}
          className="bg-indigo-600 text-white font-bold py-3 px-6 rounded-full shadow-lg transition-all duration-200 ease-in-out text-lg"
        >
          Submit Hernia Report
        </motion.button>
      </div>
    </div>
  );
};

export default HerniaFormPreview;